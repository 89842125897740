import React, { Component } from "react";
import "./assets/css/tailwind.css";
import "./assets/scss/tailwind.scss";

import "./assets/scss/icons.scss";

import { Route, Routes } from "react-router-dom";

import Index from "./pages/index.js";

import Loader from "./component/Loader";
import NotFound from "./pages/NotFound/NotFound";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.handleRouteChange = this.handleRouteChange.bind(this);
  }

  componentDidMount() {
    document.documentElement.setAttribute("dir", "ltr");
    this.handleRouteChange();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.handleRouteChange();
    }
  }

  // Route change method
  handleRouteChange() {
    this.setState({ loading: true });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.loading && <Loader />}
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Fragment>
    );
  }
}

export default App;

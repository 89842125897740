import React, { Component } from "react";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import { Link } from "react-router-dom";
import FocusIcon from "@iconscout/react-unicons/icons/uil-expand-alt";

import Image1 from "../assets/images/gallery/Meet Linda.png";
import Image2 from "../assets/images/gallery/HRMonthly.png";
import Image3 from "../assets/images/gallery/LSI.png";
import Image4 from "../assets/images/gallery/GSI.png";
import Image5 from "../assets/images/gallery/CPHR.png";
import Image6 from "../assets/images/gallery/AHRI.png";
import Image7 from "../assets/images/gallery/IECL.jpg";

import Background from "../assets/images/bg-01.jpg";

const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7];

/**
 * Portfolio section
 */
class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectList: [
        {
          image: Image1,
          title: "Meet gallery",
          subtext: "Branding",
        },
        {
          image: Image2,
          title: "HR Monthly",
          subtext: "Achievement",
        },
        {
          image: Image3,
          title: "LSI",
          subtext: "Certification",
        },
        {
          image: Image4,
          title: "GSI",
          subtext: "Certification",
        },
        {
          image: Image5,
          title: "CPHR certified",
          subtext: "Certification",
        },
        {
          image: Image6,
          title: "AHRI Certified",
          subtext: "Certification",
        },
        {
          image: Image7,
          title: "IECL Certificate",
          subtext: "Certification",
        },
      ],
      isOpenMenu: true,
      photoIndex: 0,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    return (
      <React.Fragment>
        {/* Project Start  */}
        <section
          className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800 active"
          id="accreditation"
        >
          <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
              <h6 className="text-purple-600 text-base font-medium uppercase mb-2">
                Certifications & Achievements
              </h6>
              {/* <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
                Our Works &amp; Projects
              </h3> */}

              <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
                I am an AHRI certified HR Practitioner, organisational and
                career/leadership coach (IECL) and hold accreditation as a Life
                Style Inventory (LSI) coach and Organisational Culture Inventory
                (OCI) through Human Synergistics.
              </p>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-6 mt-8">
              {this.state.projectList.map((item, index) => (
                <div
                  className="relative rounded-md shadow-sm overflow-hidden group"
                  key={index}
                >
                  <img
                    src={item.image}
                    className="group-hover: group-hover:scale-110  transition duration-500"
                    alt="workimage"
                  />
                  <div className="absolute inset-0 group-hover:bg-black opacity-50 transition duration-500 z-0"></div>

                  <div className="content">
                    <div className="icon absolute z-10 opacity-0 group-hover:opacity-100 top-4 end-4 transition-all duration-500">
                      <Link
                        to="#"
                        onClick={() =>
                          this.setState({ isOpen: true, photoIndex: index })
                        }
                        className="btn bg-purple-600 hover:bg-purple-700 border-purple-600 hover:border-purple-700 text-white btn-icon rounded-full lightbox"
                      >
                        <FocusIcon />
                      </Link>
                    </div>
                    <div className="absolute z-10 opacity-0 group-hover:opacity-100 bottom-4 start-4 transition-all duration-500">
                      <Link
                        to="#"
                        className="h6 text-md font-medium text-white hover:text-purple-600 transition duration-500"
                      >
                        {item.title}
                      </Link>
                      <p className="text-slate-100 tag mb-0">{item.subtext}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {isOpen && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length]
                }
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (photoIndex + images.length - 1) % images.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % images.length,
                  })
                }
              />
            )}
          </div>
        </section>
        {/* Project End  */}
        <section
          style={{ backgroundImage: `url(${Background})` }}
          className="py-24 w-full table relative bg-center bg-cover"
        >
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="container relative">
            <div className="grid grid-cols-1 pb-8 text-center">
              <h3 className="mb-6 md:text-3xl text-2xl text-white font-medium">
                Ready to connect with me now?
              </h3>

              <p className="text-white opacity-50 max-w-xl mx-auto">
                Connect with me to get customised services depending on your
                need.
              </p>

              <div className="relative mt-10">
                <a
                  href="#contact"
                  className="btn bg-purple-600 hover:bg-purple-700 border-purple-600 hover:border-purple-700 text-white rounded-md"
                >
                  Contact Me!
                </a>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Portfolio;

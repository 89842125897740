import React, { Component } from "react";
import { Link } from "react-router-dom";

import Logo from "../assets/images/navLogo.png";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        {/*  Start Footer  */}
        <footer className="py-8 bg-slate-800 dark:bg-gray-900">
          <div className="container">
            <div className="grid md:grid-cols-12 items-center">
              <div className="md:col-span-3">
                <a className="logo-footer">
                  <img
                    src={Logo}
                    className="md:ms-0 mx-auto"
                    alt=""
                    style={{ height: "3.5rem" }}
                  />
                </a>
              </div>

              <div className="md:col-span-5 md:mt-0 mt-8">
                <div className="text-center">
                  <p className="text-gray-400">
                    © {new Date().getFullYear()}{" "}
                    <i className="mdi mdi-heart text-purple-700"></i> by{" "}
                    <Link
                      to="https://bhuwanuprety.me/"
                      target="_blank"
                      className="text-reset text-white"
                    >
                      @ubhuwan
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* <!-- End Footer --> */}

        {/* <!-- Back to top --> */}
        <Link
          to="home"
          id="back-to-top"
          className="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-blue-600 text-white leading-9"
        >
          <i className="uil uil-arrow-up"></i>
        </Link>
        {/* <!-- Back to top --> */}
      </React.Fragment>
    );
  }
}

export default Footer;

import React, { Component, useState } from "react";

import ReadMoreReact from "read-more-react";
import TinySlider from "tiny-slider-react";


import "tiny-slider/dist/tiny-slider.css";
import { Link } from "react-router-dom";

const Testimonial = () => {
  const settings = {
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    controlsText: [
      '<i class="mdi mdi-chevron-left "></i>',
      '<i class="mdi mdi-chevron-right"></i>',
    ],
    gutter: 0,
    responsive: {
      768: {
        items: 2,
      },
    },
  };

  const review = [
    {
      id: "1",
      // profile: male,
      name: "Turin Prasantha",
      designation: "Executive Manager Development People and Culture",
      description:
        "Goodwin Aged Care highly recommends Linda Davey Consultancy services to any organisation seeking guidance on industrial relations and staff development. Linda's exceptional support in these areas, along with her consulting and management coaching, has been invaluable to Goodwin. Linda contributed significantly to our HR department and provided guidance and expertise that went above and beyond. Her professionalism and commitment to excellence make her a true asset to any organisation. I highly recommend Linda's services to anyone seeking high-level HR consultancy and support.",
    },
    {
      id: "2",
      // profile: female,
      name: "Jody Perry",
      designation: "QPRC Service Manager (previous)",
      description:
        "What connects Linda to others is her passion to make a difference to the people & organisation she serves. She is an innovative problem solver, with a constructive approach, drawing on her applied HR experience. Linda has extensive training & facilitation skills across a varied knowledge base. She takes a holistic and adaptive approach encouraging and inspiring others to do the same. We would have no hesitation in working with Linda again.",
    },
  ];

  return (
    <React.Fragment>
      {/* Review Start  */}
      <section
        className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
        id="testimonial"
      >
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-purple-600 text-base font-medium uppercase mb-2">
              Testimonial
            </h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
              Client's Review
            </h3>

            {/* <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
              Happy Clients benefitted by my service.
            </p> */}
          </div>

          <div className="grid grid-cols-1 mt-8 relative">
            <div className="tiny">
              <TinySlider settings={settings}>
                {review.map((el, index) => (
                  <div className="tiny-slide" key={index}>
                    <div className="lg:flex p-6 lg:p-1 relative rounded-md shadow shadow-slate-200 dark:shadow-slate-700 bg-white dark:bg-slate-900 overflow-hidden m-2">
                      {/* <img
                        className=" lg:h-auto lg:rounded-none rounded-full mx-auto my-auto"
                        src={el.profile}
                        alt=""
                        width="248"
                        height="180"
                      /> */}
                      <div className="pt-6 lg:p-6 text-center lg:text-left space-y-4">
                        <div className="text-base text-slate-500 dark:text-slate-200 quotes">
                          <ReadMoreReact
                            text={el.description}
                            min={100}
                            ideal={160}
                            max={165}
                            readMoreText={" Read more.."}
                          />
                        </div>
                        <div className="mt-5"></div>
                        <div>
                          <span className="text-blue-600 block mb-1">
                            {el.name}
                          </span>
                          <span className="text-slate-400 text-sm dark:text-white/60 block">
                            {el.designation}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </TinySlider>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Testimonial;

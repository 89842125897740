import React, { Component } from "react";
import { Link } from "react-router-dom";

import EVP from "../assets/images/services/EVP.png";
import EVPLight from "../assets/images/services/EVP.png";

import ER from "../assets/images/services/ER.png";
import ERLight from "../assets/images/services/ER.png";

import Coaching from "../assets/images/services/Meeting.png";
import CoachingLight from "../assets/images/services/Meeting.png";

import Training from "../assets/images/services/Training.png";
import TrainingLight from "../assets/images/services/Training.png";

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team: [
        {
          title: "Employee/Industrial Relations",
          icon: ER,
          darkIcon: ERLight,
          bullets: [],
          subtext:
            "I know that sometimes managing your people takes precious time out of your day. I can partner with " +
            "you to manage that performance issue, workplace bullying allegation or high absenteeism due to " +
            "ongoing medical certificates. I can guide you to navigate your way through the legal framework to " +
            "mitigate any long term risk and assist in workplace investigations.",
        },
        {
          title: "Employee Value Proposition (EVP)",
          icon: EVP,
          darkIcon: EVPLight,
          subtext:
            "Helping you understand and share what employees like best about working for you. Remember your organisation is unique. I can support your business drivers by reviewing and auditing HR processes and EVP programs:",

          bullets: [
            "Policy",
            "Performance/IR Frameworks",
            "Work Health and Safety",
            "Workplace Mental Health",
            "Psychosocial Risks",
            "Health and Well-being Programs",
            "Diversity, Equity and Inclusion",
          ],
        },
        {
          title: "Coaching and Mentoring",
          bullets: [],
          icon: Coaching,
          darkIcon: CoachingLight,
          subtext:
            "Not sure if the job you are in is the right fit? Are you inspiring to get that promotion? Is the working " +
            "relationship breaking down? I'm passionate about mentoring and helping others recognise their own " +
            "strengths, and transferable skills. Through professional / career coaching, we can explore together " +
            "your strengths to develop a growth mindset and give you the confidence to take that leap of faith!",
        },

        {
          title: "Training Programs",
          bullets: [],
          icon: Training,
          darkIcon: TrainingLight,
          subtext:
            "I know that managing your team can be made more challenging with the inclusion of complex issues " +
            "such as mental health impacting performance and sometimes having ‘that conversation’ is hard. I " +
            "can help you navigate and balance both the requirements of the business and the well-being of team " +
            "members through the training program; Managing Complex Performance, Including Mental Health. " +
            "Leaders will come away from this course with a clear understanding of what is expected of them, " +
            "along with practical tools, tips and supportive interventions. " +
            "Need more? I will partner with you to design and tailor training to suit your needs. ",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        {/* Start */}
        <section className="relative md:py-24 py-16 active" id="services">
          <div className="container lg mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 pb-8 items-center">
              <div>
                <h6 className="text-purple-600 text-base font-medium uppercase mb-2">
                  Services
                </h6>
                <h3 className="mb-4 md:text-2xl text-xl font-semibold dark:text-white md:mb-0">
                  Perfect Solution For Your <br /> Business
                </h3>
              </div>

              <div>
                <p className="text-slate-400 dark:text-slate-300 max-w-xl">
                  
                </p>
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-6">
              {this.state.team.map((item, key) => (
                <div
                  key={key}
                  className={`features p-6 ${
                    key % 2 === 0
                      ? "hover:shadow-xl hover:shadow-slate-100 dark:hover:shadow-slate-800"
                      : "shadow-xl shadow-slate-100 dark:shadow-slate-800"
                  } transition duration-500 rounded-3xl mt-8`}
                >
                  <div className="w-16 h-16 bg-purple-600/5 text-purple-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                    {/* <i className={`uil uil-${item.icon}`}></i> */}
                    {/* <img
                      className="prefer-light"
                      src={item.icon}
                      alt="services"
                    /> */}
                    <span className="inline-block dark:hidden">
                      <img
                        src={item.icon}
                        className="l-dark hidden prefer-light"
                        alt="services"
                      />
                      <img
                        src={item.icon}
                        className="l-light prefer-light "
                        alt="services"
                      />
                    </span>
                    <img
                      src={item.darkIcon}
                      className="hidden dark:inline-block prefer-dark"
                      alt=""
                    />
                  </div>

                  <div className="content mt-7">
                    <Link
                      to="#"
                      className="text-lg hover:text-purple-600 dark:text-white dark:hover:text-purple-600 transition-all duration-500 ease-in-out font-medium"
                    >
                      {item.title}
                    </Link>
                    <p className="text-slate-400 mt-3">
                      {item.subtext}
                      {item.bullets !== []
                        ? item.bullets.map((el) => {
                            return <li>{el}</li>;
                          })
                        : ""}
                    </p>

                    {/* <div className="mt-5">
                      <Link
                        to="#"
                        className="btn btn-link hover:text-purple-600 dark:hover:text-purple-600 after:bg-purple-600 dark:text-white transition duration-500"
                      >
                        Read More <i className="uil uil-arrow-right"></i>
                      </Link>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* <div className="container md:mt-24 mt-16">
            <div className="grid grid-cols-1 pb-8 text-center">
              <h6 className="text-purple-600 text-base font-medium uppercase mb-2">
                Service breakdown
              </h6>
              <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
                Human Resource Solutions For Your Business
              </h3>

              <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
                Connect with me and benefit from my expertise in all things HR.
              </p>
            </div>

            <div className="grid grid-cols-1 mt-8">
              <div className="timeline relative">
                <div className="timeline-item ">
                  <div className="grid sm:grid-cols-2">
                    <div className="">
                      <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                        <img
                          src={TransformColor}
                          className="h-20 w-20"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="mt-4 md:mt-0">
                      <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                        <h5 className="text-lg dark:text-white mb-1 font-medium">
                          Employee Value Proposition (EVP)
                        </h5>
                        <p className="timeline-subtitle mt-3 mb-0 text-slate-400">
                          Exploring ways with you to develop human-centered
                          workforce experiences and employee engagement. Helping
                          you transform HR so your business and people deliver
                          more value, better outcomes, and increased
                          productivity,
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="timeline-item mt-5 pt-4">
                  <div className="grid sm:grid-cols-2">
                    <div className="md:order-1 order-2">
                      <div className="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">
                        <h5 className="text-lg dark:text-white mb-1 font-medium">
                          Coaching and mentoring
                        </h5>
                        <p className="timeline-subtitle mt-3 mb-0 text-slate-400">
                          I'm passionate about mentoring, helping others
                          recognise strengths, skills, and challenges through
                          experiential learning. Through professional / career
                          coaching, together we can explore strengths to develop
                          a growth mindset,
                        </p>
                      </div>
                    </div>
                    <div className="md:order-2 order-1">
                      <div className="duration duration-right md:ms-7 relative">
                        <img src={CoachingColor} className="h-20 w-20" alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="timeline-item mt-5 pt-4">
                  <div className="grid sm:grid-cols-2">
                    <div className="mt-4 mt-sm-0">
                      <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                        <img
                          src={FacilitationColor}
                          className="h-20 w-20"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="mt-4 mt-sm-0">
                      <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                        <h5 className="text-lg dark:text-white mb-1 font-medium">
                          Training Programs
                        </h5>
                        <p className="timeline-subtitle mt-3 mb-0 text-slate-400">
                          As a credible facilitator, I create synergy, generate
                          ideas, and apply learning insights. I will partner
                          with you to design training programs such as;
                          Workplace Mental health supports, Managing complexed
                          performance management, Work Health and Safety and a
                          range of personal and professional learning,
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </section>
      </React.Fragment>
    );
  }
}

export default Services;

import React, { Component } from "react";
import { Link as Link2 } from "react-router-dom";

import AboutImage from "../assets/images/about-image.jpg";
import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
          id="about"
        >
          <div className="container mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
              <div className="lg:col-span-5">
                <div className="relative">
                  <img
                    src={AboutImage}
                    className="rounded-lg shadow-lg relative"
                    alt=""
                  />
                </div>
              </div>
              {/* end col */}

              <div className="lg:col-span-7">
                <div className="lg:ms-7">
                  <h6 className="text-purple-600 text-base font-medium uppercase mb-2">
                    Who am I ?
                  </h6>
                  <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
                    Linda Davey
                  </h3>

                  <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">
                    I am a people strategist, certified practitioner in HR,
                    credible facilitator and organisational/leadership coach. I
                    have over 20 year’s HRM experience working with private
                    industry, non for profit and government sectors including
                    local Councils. <br /> <br /> I have strategic and
                    operational corporate knowledge in all facets of HRM and in
                    addition, I partner with managers and executives to educate
                    in underperformance/misconduct to protect the business
                    against adverse action.
                    <br /> <br /> I believe that when you align your people with
                    your core business principles, values and purpose, through
                    best practice and contemporary human resource solutions, it
                    allows your people to take ownership, shaping your culture.
                  </p>

                  <div className="relative mt-10">
                    <Link2
                      to="https://www.linkedin.com/in/linda-davey-cphr-398848141"
                      className="btn bg-purple-600 hover:bg-purple-700 border-purple-600 hover:border-purple-700 text-white rounded-md"
                      target="_blank"
                    >
                      LinkedIn
                    </Link2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;

import React, { Component } from "react";

import ACT from "../assets/images/trusted/ACT.png";
import ACTLight from "../assets/images/trusted/ACT-white.png";

import Goodwin from "../assets/images/trusted/Goodwin.png";
import GoodwinLight from "../assets/images/trusted/Goodwin-white.png";

import QPRC from "../assets/images/trusted/QPRC.png";
import QPRCLight from "../assets/images/trusted/QPRC-light.png";

import LGP from "../assets/images/trusted/LGP.png";
import LGPLight from "../assets/images/trusted/LGP-light.png";

import CIT from "../assets/images/trusted/CIT.png";
import CITLight from "../assets/images/trusted/CIT-light.png";

import AG from "../assets/images/trusted/AG.png";
import AGLight from "../assets/images/trusted/AG-white.png";

class TrustedBy extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
          id="trusted-by"
        >
          <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
              <h6 className="text-purple-600 text-base font-medium uppercase mb-2">
                Trusted By
              </h6>
            </div>
          </div>

          <div className="container mt-12">
            <div className="grid md:grid-cols-4  sm:grid-cols-2  justify-center gap-8">
              <div className="mx-auto">
                <span className="inline-block dark:hidden">
                  <img
                    src={ACTLight}
                    className="l-dark hidden h-[110px]"
                    alt=""
                  />
                  <img src={ACT} className="l-light h-[110px]" alt="" />
                </span>
                <img
                  src={ACTLight}
                  className="hidden dark:inline-block h-[110px]"
                  alt=""
                />
              </div>

              <div className="mx-auto">
                <span className="inline-block dark:hidden">
                  <img src={AGLight} className="l-dark hidden h-[110px]" alt="" />
                  <img src={AG} className="l-light h-[110px]" alt="" />
                </span>
                <img
                  src={AGLight}
                  className="hidden dark:inline-block h-[110px]"
                  alt=""
                />
              </div>

              <div className="mx-auto">
                <span className="inline-block dark:hidden">
                  <img
                    src={GoodwinLight}
                    className="l-dark hidden h-[110px]"
                    alt=""
                  />
                  <img src={Goodwin} className="l-light h-[110px]" alt="" />
                </span>
                <img
                  src={GoodwinLight}
                  className="hidden dark:inline-block h-[110px]"
                  alt=""
                />
              </div>

              <div className="mx-auto">
                <span className="inline-block dark:hidden">
                  <img
                    src={QPRCLight}
                    className="l-dark hidden h-[110px]"
                    alt=""
                  />
                  <img src={QPRC} className="l-light h-[110px]" alt="" />
                </span>
                <img
                  src={QPRCLight}
                  className="hidden dark:inline-block h-[110px]"
                  alt=""
                />
              </div>

              <div className="mx-auto ">
                <span className="inline-block dark:hidden">
                  <img
                    src={LGPLight}
                    className="l-dark hidden h-[110px]"
                    alt=""
                  />
                  <img src={LGP} className="l-light h-[110px]" alt="" />
                </span>
                <img
                  src={LGPLight}
                  className="hidden dark:inline-block h-[110px]"
                  alt=""
                />
              </div>

              <div className="mx-auto">
                <span className="inline-block dark:hidden">
                  <img
                    src={CITLight}
                    className="l-dark hidden h-[110px]"
                    alt=""
                  />
                  <img src={CIT} className="l-light h-[110px]" alt="" />
                </span>
                <img
                  src={CITLight}
                  className="hidden dark:inline-block h-[110px]"
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* <!--end container--> */}
        </section>
      </React.Fragment>
    );
  }
}

export default TrustedBy;

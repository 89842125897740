import React, { Component, useRef } from "react";

import { sendForm } from "@emailjs/browser";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    sendForm(
      "service_bllog3r",
      "template_c9ac4l9",
      form.current,
      "oRP8aoEMXk8zktyKj"
    ).then(
      (result) => {
        e.target.reset();
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <React.Fragment>
      <section
        className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
        id="contact"
      >
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-purple-600 text-base font-medium uppercase mb-2">
              Contact Me
            </h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
              Get In Touch !
            </h3>

            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
              Connect with me to transform your organisation through people
              development. Get in touch with me for a chat over coffee, or just
              give me a call.
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-6">
            <div className="lg:col-span-8">
              <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
                <form name="myForm" id="myForm" ref={form} onSubmit={sendEmail}>
                  <p className="mb-0" id="error-msg"></p>
                  <div id="simple-msg"></div>
                  <div className="grid lg:grid-cols-12 lg:gap-6">
                    <div className="lg:col-span-6 mb-5">
                      <input
                        name="username"
                        id="username"
                        type="text"
                        className="form-input focus:border-purple-600"
                        placeholder="Name :"
                      />
                    </div>

                    <div className="lg:col-span-6 mb-5">
                      <input
                        name="email"
                        id="email"
                        type="email"
                        className="form-input focus:border-purple-600"
                        placeholder="Email :"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1">
                    <div className="mb-5">
                      <input
                        name="subject"
                        id="subject"
                        className="form-input focus:border-purple-600"
                        placeholder="Subject :"
                      />
                    </div>

                    <div className="mb-5">
                      <textarea
                        name="message"
                        id="message"
                        className="form-input textarea h-28 focus:border-purple-600"
                        placeholder="Message :"
                      ></textarea>
                    </div>
                  </div>
                  <button
                    type="submit"
                    id="submit"
                    name="send"
                    className="btn bg-purple-600 hover:bg-purple-700 border-purple-600 hover:border-purple-700 text-white rounded-md h-11 justify-center flex items-center"
                  >
                    Send Message
                  </button>
                </form>
              </div>
            </div>

            <div className="lg:col-span-4">
              <div className="lg:ms-8">
                <div className="flex">
                  <div className="icons text-center mx-auto">
                    <i className="uil uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                  </div>

                  <div className="flex-1 ms-6">
                    <h5 className="text-lg dark:text-white mb-2 font-medium">
                      Phone
                    </h5>
                    <a href="tel:+152534-468-854" className="text-slate-400">
                      0439 435 296
                    </a>
                  </div>
                </div>

                <div className="flex mt-4">
                  <div className="icons text-center mx-auto">
                    <i className="uil uil-envelope block rounded text-2xl dark:text-white mb-0"></i>
                  </div>

                  <div className="flex-1 ms-6">
                    <h5 className="text-lg dark:text-white mb-2 font-medium">
                      Email
                    </h5>
                    <a
                      href="mailto:contact@example.com"
                      className="text-slate-400"
                    >
                      linda.hrconsulting@outlook.com.au
                    </a>
                  </div>
                </div>

                <div className="flex mt-4">
                  <div className="icons text-center mx-auto">
                    <i className="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"></i>
                  </div>

                  <div className="flex-1 ms-6">
                    <h5 className="text-lg dark:text-white mb-2 font-medium">
                      Location
                    </h5>
                    <p className="text-slate-400 mb-2">Canberra, Australia</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Contact;

import React, { Component } from "react";
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";

import LinkedIn from "@iconscout/react-unicons/icons/uil-linkedin";

import NavLogo from "../assets/images/navLogo.png";
import NavLogoLight from "../assets/images/navLogo.png";

import Typed from "typed.js";

import BackgroundImage from "../assets/images/bg/bgImage.jpg";
import Services from "../component/Services";
import About from "../component/About";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Accreditation from "../component/Accreditation";
import Review from "../component/Testimonial";
import Switcher from "../component/Switcher";
import TrustedBy from "../component/TrustedBy";

import "./index.css";

/**
 * Index Component
 */
class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenMenu: true,
      arrow: false,
      links: [
        { title: "Home", to: "home" },
        { title: "About Me", to: "about" },
        { title: "Services", to: "services" },
        { title: "Accreditation", to: "accreditation" },
        { title: "Testimonial", to: "testimonial" },
        { title: "Contact", to: "contact" },
      ],
    };

    this.toggleMenu = this.toggleMenu.bind(this);

    this.handleScroll = this.handleScroll.bind(this);

    window.addEventListener("scroll", this.windowScroll);

    setTimeout(() => {
      new Typed("#typed", {
        strings: [
          "<b>assets</b>",
          "<b>strength</b>",
          "<b>advantage</b>",
          "<b>resources</b>",
        ],
        backDelay: 2000,
        loop: true,
        startDelay: 300,
        typeSpeed: 100,
        backSpeed: 100,
      });
    }, 250);
  }

  handleScroll() {
    if (
      document.body.scrollTop >= 100 ||
      document.documentElement.scrollTop >= 100
    ) {
      this.setState({
        arrow: true,
      });
    } else {
      this.setState({
        arrow: false,
      });
    }
  }

  /**
   * Window scroll
   */
  windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("is-sticky");
    } else {
      navbar.classList.remove("is-sticky");
    }
  }

  toggleMenu = () => {
    this.setState({
      isOpenMenu: !this.state.isOpenMenu,
    });
    // const el = document.getElementById(event.currentTarget.id);
    // el.classList.add("active");
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  render() {
    const toggleClass = this.state.isOpenMenu ? "hidden" : "";

    return (
      <React.Fragment>
        <div>
          <nav className="navbar" id="navbar">
            <div className="container flex flex-wrap items-center justify-end">
              <a className="navbar-brand" href="index.html">
                <span className="inline-block dark:hidden">
                  <img src={NavLogoLight} className="l-dark h-15" alt="" />
                  <img src={NavLogo} className="l-light h-15" alt="" />
                </span>
                <img
                  src={NavLogoLight}
                  className="hidden dark:inline-block h-15"
                  alt=""
                />
              </a>

              <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
                <ul className="list-none menu-social mb-0">
                  <li className="inline ms-1">
                    <Link2
                      to="https://www.linkedin.com/in/linda-davey-cphr-398848141"
                      target="_blank"
                    >
                      <span className="login-btn-primary">
                        <span className="btn btn-sm btn-icon rounded-full bg-purple-600 hover:bg-purple-700 border-purple-600 hover:border-purple-700 text-white">
                          <LinkedIn />
                        </span>
                      </span>
                      <span className="login-btn-light">
                        <span className="btn btn-sm btn-icon rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                          <LinkedIn />
                        </span>
                      </span>
                    </Link2>
                  </li>
                </ul>
                <button
                  type="button"
                  className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
                  onClick={this.toggleMenu}
                >
                  <span className="sr-only">Navigation Menu</span>
                  <i className="mdi mdi-menu mdi-24px"></i>
                </button>
              </div>

              <div
                className={`${toggleClass} navigation lg_992:order-1 lg_992:flex`}
                id="menu-collapse"
              >
                <ul className="navbar-nav nav-light" id="navbar-navlist">
                  {this.state.links.map((el, key) => (
                    <Link
                      className="nav-item"
                      to={el.to}
                      activeclassname="active"
                      spy={true}
                      smooth={true}
                      duration={250}
                      onClick={this.toggleMenu}
                      key={key}
                      id={key}
                    >
                      <span className="nav-link">{el.title}</span>
                    </Link>
                  ))}
                </ul>
              </div>
            </div>
          </nav>
          <section
            style={{ backgroundImage: `url(${BackgroundImage})` }}
            className="py-36 lg:py-64 w-full table relative bg-center bg-cover"
            id="home"
          >
            <div className="absolute inset-0 bg-black opacity-80"></div>
            <div className="container relative">
              <div className="grid grid-cols-1 mt-12">
                <h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
                  Helping your people <br />
                  to be your greatest{" "} 
                  <br className="mobile-only" />
                  <span
                    className="typewrite relative text-type-element"
                    id="typed"
                    data-period="2000"
                    data-type="[ 'assets', 'strength', 'advantage', 'resources' ]"
                  ></span>
                </h4>

                <p className="text-white opacity-50 mb-0 max-w-2xl text-lg">
                  Connect with me to partner and serve you in people solutions
                  and all things HR.
                </p>

                <div className="relative mt-10">
                  <a
                    href="#services"
                    className="btn bg-purple-600 hover:bg-purple-700 border-purple-600 hover:border-purple-700 text-white rounded-md"
                  >
                    Explore solutions
                  </a>
                </div>
              </div>
            </div>
          </section>
          {/* About section */}
          <About />
          {/* Trusted by section */}
          <TrustedBy />
          {/* Service section */}
          <Services />
          {/* Portfolio section */}
          <Accreditation />
          {/* Review section */}
          <Review />
          {/* Contact section */}
          <Contact />
          {/* Footer section */}
          <Footer />
          {/* Switcher section */}
          <Switcher />
        </div>
      </React.Fragment>
    );
  }
}

export default Index;
